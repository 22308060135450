/* globals FormData, fetch */
import React, { useCallback, useState, useRef } from 'react';
import * as styles from './Form.module.css';

const Form = ({ onSuccessfulSubmit }) => {
  const [selected, setSelected] = useState('');
  const formElement = useRef(null);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let formData = new FormData(formElement.current);

      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams(formData).toString(),
        });

        onSuccessfulSubmit();
      } catch (e) {
        console.error(e);
      }
    },
    [onSuccessfulSubmit]
  );

  return (
    <div>
      <form
        ref={formElement}
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/thank-you/"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />

        <div className="space-y-6">
          <p>
            <label className={styles.formLabel}>
              <span>Name*</span>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className={styles.formInput}
                required
              />
            </label>
          </p>
          <p>
            <label className={styles.formLabel}>
              <span>Work Email*</span>
              <input
                type="email"
                name="email"
                placeholder="Work Email"
                className={styles.formInput}
                required
              />
            </label>
          </p>
          <p>
            <label className={styles.formLabel}>
              <span>Phone Number</span>
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                className={styles.formInput}
              />
            </label>
          </p>
          <p>
            <label className={styles.formLabel}>
              <span>Organisation Name</span>
              <input
                type="text"
                name="organisation"
                placeholder="Organisation Name"
                className={styles.formInput}
              />
            </label>
          </p>
          <p>
            <label className={styles.formLabel}>
              <span>Date of Event</span>
              <input
                type="text"
                name="date"
                placeholder="Date of Event"
                className={styles.formInput}
              />
            </label>
          </p>
          <p>
            <label className={styles.formLabel}>
              <span>What package are you interested in?</span>
              <select
                id="package"
                className={`${styles.selectInput} text-bluesh-grey`}
                defaultValue={selected}
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
              >
                <option value="" disabled>
                  Choose a package
                </option>
                <option value="virtual">Virtual Event</option>
                <option value="on-site">On-Site Event</option>
              </select>
            </label>
          </p>
          {selected === 'on-site' ? (
            <p>
              <label className={styles.formLabel}>
                <span>Venue</span>
                <input type="text" name="venue" placeholder="Venue" className={styles.formInput} />
              </label>
            </p>
          ) : (
            ''
          )}
          <div className="mt-6 space-y-4">
            <p className={styles.formLabel}>
              <span>Do you like any of the following?</span>
            </p>
            <p className={styles.formCheckbox}>
              <input type="checkbox" id="multilingual" name="multilingual" value="multilingual" />
              <label htmlFor="multilingual">Multilingual streaming</label>
            </p>
            <p className={styles.formCheckbox}>
              <input type="checkbox" id="branded" name="branded" value="branded" />
              <label htmlFor="branded">
                Branding assets (event mini-site, graphics, title animation)
              </label>
            </p>
            <p className={styles.formCheckbox}>
              <input type="checkbox" id="consulting" name="consulting" value="consulting" />
              <label htmlFor="consulting">Audio & Visual consulting</label>
            </p>
          </div>
          <div className="mt-6">
            <p className={styles.formLabel}>
              <span>Have you run online events before?</span>
            </p>
            <div className="flex items-center space-x-8 mt-4">
              <p className={styles.formRadio}>
                <input type="radio" id="yes" name="radio" />
                <label htmlFor="yes">Yes</label>
              </p>
              <p className={styles.formRadio}>
                <input type="radio" id="no" name="radio" />
                <label htmlFor="no">No</label>
              </p>
            </div>
          </div>
          <div className="mt-6">
            <p>
              <label className={styles.formLabel}>
                <span>Anything else you can share about your event?</span>
                <textarea type="text" name="additional" className={styles.formInput}></textarea>
              </label>
            </p>
          </div>
          <div className="pt-8">
            <button
              type="submit"
              className="
                bg-primary focus-ring hover:bg-dark-primary transition
                text-white text-lg font-bold py-4 px-20 rounded-md
              "
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
