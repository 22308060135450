import React from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';
import SEO from '../components/SEO';

import metaImage from '../images/meta/get-quote.jpg';

const GetQuote = () => {
  return (
    <div>
      <SEO
        title="Get a Quote"
        description="Get a quote for live streaming your virtual or on-site event, based on you unique needs."
        image={metaImage}
      />

      <Header />

      <main
        className="
          bg-dark-white pt-10 sm:pt-16 md:pt-24 lg:pt-28 xl:pt-32
          pb-14 sm:pb-20 md:pb-28 lg:pb-32 xl:pb-36
        "
      >
        <div className="container">
          <div className="grid grid-cols-6 md:grid-cols-12 gap-8">
            <div className="lg:col-span-5 col-span-6">
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-blue font-bold">
                Get a Quote
              </h1>

              <div className="bg-lighter-green rounded-md mt-12">
                <div className="px-9 py-7">
                  <div className="flex items-center space-x-5">
                    <StaticImage
                      src="../images/ivaylo-mladenov.jpg"
                      width={72}
                      alt="Ivaylo Mladenov, Webcast consultant"
                      className="rounded-full"
                    />
                    <div className="text-black text-opacity-70 text-base space-y-2">
                      <p className="font-bold">Ivaylo Mladenov</p>
                      <p className="font-inter">Webcast consultant</p>
                    </div>
                  </div>
                  <div className="text-base text-black text-opacity-70 font-inter space-y-3 mt-4">
                    <p>
                      Hello, this is Ivo and I am here to help you stream your next event in Europe.
                    </p>
                    <p>
                      Use the form to get pricing. Typically we respond the same day or within 24
                      hours.
                    </p>
                    <p>
                      Would you rather do this over the phone?<br></br>
                      <strong>You can call me at +32 2 881 01 15</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-6 lg:col-start-7">
              <Form onSuccessfulSubmit={() => navigate('/thank-you')} />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default GetQuote;
